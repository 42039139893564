<template>
  <div
    :data-start-nodes="startNodes"
    :data-end-nodes="endNodes"
    :style="`--align: ${align}`"
    class="input-row"
    ref="node"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

import { isNumberBetween } from "@horizon56/utils";

defineProps<{ align?: "stretch" | "flex-start" | "flex-end" }>();
const node = ref<HTMLElement>();
const startNodes = ref("");
const endNodes = ref("");

const isDisabled = (elem: Element) => elem.getAttribute("class")?.includes("isDisabled");

const updateRows = () => {
  const rows: number[][] = [];
  if (!node.value) {
    return;
  }
  let prevOffset = -1;
  for (const [key, input] of [...node.value.children].entries()) {
    mutate.observe(input, { attributes: true });
    if (isDisabled(input)) {
      rows.push([key]);
      prevOffset = -1;
      continue;
    }
    const { top, height } = input.getBoundingClientRect();
    if (isNumberBetween(top, prevOffset - height * 0.2, prevOffset + height * 0.2)) {
      rows[rows.length - 1].push(key);
    } else {
      rows.push([key]);
    }
    prevOffset = top;
  }
  startNodes.value = `|${rows.map((row) => row[0]).join("|")}|`;
  endNodes.value = `|${rows.map((row) => row[row.length - 1]).join("|")}|`;
};

const resizer = new ResizeObserver(updateRows);
const mutate = new MutationObserver(updateRows);

onMounted(() => {
  if (node.value) {
    resizer.observe(node.value);
    mutate.observe(node.value, { childList: true });
  }
});

onBeforeUnmount(() => {
  resizer.disconnect();
  mutate.disconnect();
});
</script>

<style lang="scss" scoped>
.input-row {
  $base: base-label;
  display: flex;
  flex-flow: row wrap;
  align-items: var(--align, flex-end);
  row-gap: 10px;
  :deep(.#{$base}__frame) {
    --label-radius-top-left: 0px;
    --label-radius-bottom-left: 0px;
    --label-radius-top-right: 0px;
    --label-radius-bottom-right: 0px;
  }
  @each $size in $radiusSizes {
    &:has(.#{$base}--radius-#{$size}) {
      --label-radius: var(--app-radius-#{$size});
    }
  }

  @for $i from 0 through 30 {
    &[data-start-nodes*="|#{$i}|"] :deep(.#{$base}:nth-child(#{$i + 1}) .#{$base}__frame) {
      --label-radius-top-left: var(--label-radius);
      --label-radius-bottom-left: var(--label-radius);
    }
    &[data-end-nodes*="|#{$i}|"] :deep(.#{$base}:nth-child(#{$i + 1}) .#{$base}__frame) {
      --label-radius-top-right: var(--label-radius);
      --label-radius-bottom-right: var(--label-radius);
    }
  }
}
</style>
